import { Course, CourseOccurrenceBase, Person } from "@/interfaces/api";

class MappingHelper {
  /**
   * Maps an array of full CourseOccurrenceBase objects to an array of partial CourseOccurrenceBase objects
   *
   * @param {Array<CourseOccurrenceBase>} courseOccurrences - An array of full CourseOccurrenceBase objects
   *
   * @returns {Array<Partial<CourseOccurrenceBase>>>} An array of partial CourseOccurrenceBase objects
   */
  mapToPartialCourseOccurrenceOverviewArray(
    courseOccurrences: Array<CourseOccurrenceBase>
  ): Array<Partial<CourseOccurrenceBase>> {
    return courseOccurrences.map((courseOccurrence: CourseOccurrenceBase) => {
      return {
        id: courseOccurrence.id,
        occurrence: courseOccurrence.occurrence,
        titleEn: courseOccurrence.titleEn,
        titleSv: courseOccurrence.titleSv,
        courseStartDate: courseOccurrence.courseStartDate,
        courseEndDate: courseOccurrence.courseEndDate,
        status: courseOccurrence.status,
        coordinatorName: courseOccurrence.coordinatorName,
        studentCount: courseOccurrence.studentCount,
        noteTakerCount: courseOccurrence.noteTakerCount,
        proposedNoteTakersCount: courseOccurrence.proposedNoteTakersCount,
        latestNoteTakerOffer: courseOccurrence.latestNoteTakerOffer,
      };
    });
  }

  /*
   * Maps the course array to an array of strings with course code, term and title (acoording to specified locale)
   *
   * @param {Array<Course>} courses - An array of full Course objects
   * @param {string} i18nLocale - The value of the locale property of the VueI18n instance
   *
   * @returns {Array<string>} An array of strings with course code, term and title (acoording to specified locale)
   */
  mapCourseOccurrenceAndTitles(
    courses: Array<Course>,
    i18nLocale: string
  ): Array<string> {
    const mappedCourses = courses.map((course: Course) =>
      i18nLocale === "sv"
        ? `${course.courseCode} ${course.term}: ${course.titleSv}`
        : `${course.courseCode} ${course.term}: ${course.titleEn}`
    );
    return mappedCourses;
  }

  /*
   * Maps the person array to an array of strings which are links to the relevant details view
   *
   * @param {Array<Person>} personList - An array of Person objects
   * @param {string} detailsViewRoute - The route to the details view
   *
   * @returns {Array<string>} An array of strings which are links to the relevant details view
   */
  mapPersonToDetailViewLinks(
    personList: Array<Person>,
    detailsViewRoute: string
  ): Array<string> {
    const links = personList.map((person: Person) => {
      return `<a href="${detailsViewRoute}/${person.id}">${person.firstName} ${person.lastName}</a>`;
    });
    return links;
  }
}

export default new MappingHelper();
